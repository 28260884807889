import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/api.css'; // Import custom CSS

const APIs = () => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState({ name: '', description: '' });
    const [editingItem, setEditingItem] = useState(null);
    const [originalItem, setOriginalItem] = useState(null); // State to hold the original item data
    const [message, setMessage] = useState(''); // State for feedback messages
    const [messageType, setMessageType] = useState(''); // State for message type (success/error)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/api_register');
    
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setData(response.data);
                } else {
                    setData([{ name: 'db not working', description: 'this is sample data' }]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setData([{ name: 'db not working', description: 'this is sample data' }]);
            }
        };
    
        fetchData();
    }, []);

    const handleCreate = async () => {
        try {
            const response = await axios.post('/api/api_register', newItem);
            setData([...data, response.data]);
            setNewItem({ name: '', description: '' });
            setMessage('API created successfully!');
            setMessageType('success');
        } catch (error) {
            console.error('Error creating item:', error);
            setMessage('Failed to create API.');
            setMessageType('error');
        }
    };

    const handleEdit = (item) => {
        setEditingItem(item);
        setOriginalItem(item);
        setNewItem({ name: item.name, description: item.description });
    };

    const handleUpdate = async () => {
        try {
            const response = await axios.put(`/api/api_register/${editingItem._id}`, newItem);
            setData(data.map((item) => (item._id === response.data._id ? response.data : item)));
            setEditingItem(null);
            setNewItem({ name: '', description: '' });
            setOriginalItem(null);
            setMessage('API updated successfully!');
            setMessageType('success');
        } catch (error) {
            console.error('Error updating item:', error);
            setMessage('Failed to update API.');
            setMessageType('error');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/api_register/${id}`);
            setData(data.filter((item) => item._id !== id));
            setMessage('API deleted successfully!');
            setMessageType('success');
        } catch (error) {
            console.error('Error deleting item:', error);
            setMessage('Failed to delete API.');
            setMessageType('error');
        }
    };

    const handleCancel = () => {
        setEditingItem(null);
        setNewItem({ name: '', description: '' });
        setOriginalItem(null);
    };

    const handleReset = () => {
        if (originalItem) {
            setNewItem({ name: originalItem.name, description: originalItem.description });
        }
    };

    const handleCloseMessage = () => {
        setMessage('');
        setMessageType('');
    };

    return (
        <div className="container mt-4">
            <h2>API Library</h2>

            {message && (
                <div className={`alert alert-${messageType === 'success' ? 'success' : 'danger'} alert-dismissible`}>
                    {message}
                    <button type="button" className="close custom-close-button" onClick={handleCloseMessage}>
                        <span className="material-icons">close</span>
                    </button>
                </div>
            )}

            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Name"
                    value={newItem.name}
                    onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                    className="form-control mb-2"
                />
                <input
                    type="text"
                    placeholder="Description"
                    value={newItem.description}
                    onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                    className="form-control mb-2"
                />
                {editingItem ? (
                    <div>
                        <button onClick={handleUpdate} className="btn btn-dark">Update</button>
                        <button onClick={handleReset} className="btn btn-danger ms-2">Reset</button>
                        <button onClick={handleCancel} className="btn btn-light ms-2">Cancel</button>
                    </div>
                ) : (
                    <button onClick={handleCreate} className="btn btn-dark">Create</button>
                )}
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>
                                <button onClick={() => handleEdit(item)} className="btn btn-dark">Edit</button>
                                <button onClick={() => handleDelete(item._id)} className="btn btn-danger ms-2">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default APIs;
