// src/pages/home.js

import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <h1>Hey Mom</h1>
            <Link to="/status">
                <button className="btn btn-dark mt-3">
                    Status
                    <span className="material-icons" style={{ fontSize: '1.2rem', verticalAlign: 'middle', marginLeft: '5px' }}>chevron_right</span>
                </button>
            </Link>

            <Link to="/APIs">
                <button className="btn btn-dark mt-3">
                    APIs
                    <span className="material-icons" style={{ fontSize: '1.2rem', verticalAlign: 'middle', marginLeft: '5px' }}>chevron_right</span>
                </button>
            </Link>

            {/* Button to navigate to chatbot */}
            <a href="/chatbot">
                <button className="btn btn-dark mt-3">
                    Chatbot
                    <span className="material-icons" style={{ fontSize: '1.2rem', verticalAlign: 'middle', marginLeft: '5px' }}>chevron_right</span>
                </button>
            </a>
        </div>
    );
}

export default Home;
