
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Status from './pages/status';
import APIs from './pages/api';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route path='/status' element={<Status />}></Route>
          <Route path='/APIs' element={<APIs />}></Route>
        </Routes>
        
      </Router>
      
    </div>
  );
}

export default App;
