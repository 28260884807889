import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Status = () => {
    const [dbStatus, setDbStatus] = useState('');
    const [dbUrl, setDbUrl] = useState('');
    const [password, setPassword] = useState(''); // Track input for password
    const [isAuthorized, setIsAuthorized] = useState(false); // Track if user is authorized
    const [error, setError] = useState('');

    useEffect(() => {
        if (isAuthorized) {
            const fetchDbUrl = async () => {
                try {
                    const response = await axios.get('/api/fetch_db_url');
                    setDbUrl(response.data.dbUrl);
                } catch (error) {
                    console.error("Error fetching DB URL:", error);
                }
            };

            const checkDbConnection = async () => {
                try {
                    const response = await axios.get('/api/check_db_connection');
                    setDbStatus(response.data.status ? 'connected' : 'not_connected');
                } catch (error) {
                    setDbStatus('error');
                }
            };

            fetchDbUrl();
            checkDbConnection();
        }
    }, [isAuthorized]);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'admin') {
            setIsAuthorized(true);
            setError('');
        } else {
            setError('Unauthorized');
        }
    };

    const renderStatus = () => {
        if (dbStatus === 'connected') {
            return (
                <>
                    <i className="bi bi-check-circle-fill text-success" style={{ fontSize: '2rem' }}></i>
                    <p>Connected to DB</p>
                </>
            );
        } else if (dbStatus === 'not_connected') {
            return (
                <>
                    <i className="bi bi-x-circle-fill text-danger" style={{ fontSize: '2rem' }}></i>
                    <p>Not connected to DB</p>
                </>
            );
        } else {
            return (
                <>
                    <i className="bi bi-x-circle-fill text-danger" style={{ fontSize: '2rem' }}></i>
                    <p>Error checking DB connection</p>
                </>
            );
        }
    };

    if (!isAuthorized) {
        return (
            <div className="text-center">
                <h1>Enter Password</h1>
                <form onSubmit={handlePasswordSubmit}>
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control mb-2"
                        style={{ width: '300px', margin: '0 auto' }}
                    />
                    <button type="submit" className="btn btn-dark">Proceed</button>
                </form>
                {error && <p className="text-danger mt-3">{error}</p>}
                {error && (
                    <button
                        onClick={() => window.location.href = '/'}
                        className="btn btn-dark mt-2"
                    >
                        Go to Home
                    </button>
                )}
            </div>
        );
    }

    return (
        <div className="text-center">
            <h1>DB Status</h1>
            {renderStatus()}
            <h2>Database URL:</h2>
            <p>{dbUrl}</p>
        </div>
    );
};

export default Status;
